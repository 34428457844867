<template>
    <div>
        <b-row no-gutters>
            <b-col xs="12" sm="12" md="3" lg="2">
                <accordion-product></accordion-product>
            </b-col>
            <b-col xs="12" sm="12" md="9" lg="10">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import AccordionProduct from "@/components/_public/_product/AccordionProduct";
export default {
    name: "CartContent",
    components: {AccordionProduct},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
